<template>
  <div class="DeviceArea">
    <h2>管理区域</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('DeviceAreaAdd')">添加</el-button>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="workerRegionName" label="区域名称"> </el-table-column>
      <el-table-column align="right" width="210">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('DeviceAreaEdit')"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDel(scope.row)" :disabled="onCheckBtnAuthority('DeviceAreaDel')"
            >删除</el-button
          >

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="权限管理"
      :visible.sync="showFormData"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm" label-width="80px">
        <el-form-item label="区域名称" prop="workerRegionName">
          <el-input v-model="formData.workerRegionName"></el-input>
        </el-form-item>
      </el-form>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
    
<style lang="less" scoped>
.DeviceArea {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "DeviceArea",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      formData: {
        id: null,
        projectId: localStorage.getItem('projectId'),
        workerRegionName: null
      },
      formRules: {
        workerRegionName: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      showFormData: false
    };
  },
  computed: {},
  mounted() {
    this.onGetListData();
    this.onGetPageBtn();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1688364067457703937",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/rnWorkerRegion/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 关闭
    handleClose: function () {
      this.formData.id = null
      this.formData.workerRegionName = null

      this.showFormData = false
      this.$refs['ruleForm'].resetFields();
    },
    // 添加
    handleAdd: function () {
      this.formData.id = null
      this.formData.workerRegionName = null

      this.showFormData = true
    },
    // 编辑
    handleEdit: function (row) {
      this.formData.id = row.id
      this.formData.workerRegionName = row.workerRegionName

      this.showFormData = true
    },
    // 提交
    handleSubmit: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/rnWorkerRegion/saveOrUpdate",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.handleClose();
            that.onGetListData();
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDel: function (row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDel(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDel: function (id) {
      var that = this;
      var data = {
        id: id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/rnWorkerRegion/delete",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.onGetListData();
            that.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
    