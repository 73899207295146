import { render, staticRenderFns } from "./DeviceArea.vue?vue&type=template&id=024d9802&scoped=true&"
import script from "./DeviceArea.vue?vue&type=script&lang=js&"
export * from "./DeviceArea.vue?vue&type=script&lang=js&"
import style0 from "./DeviceArea.vue?vue&type=style&index=0&id=024d9802&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024d9802",
  null
  
)

export default component.exports